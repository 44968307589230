import { organizationUuid } from '@/models/Organization';
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import { Rpc } from '@/models/Rpc';
// eslint-disable-next-line import/no-duplicates
import { StatisticsColumn, StatisticsOptions } from '@/components/week-statistics-view/WeekStatisticsView';
// eslint-disable-next-line import/no-duplicates
import { ReportStatus } from '@/support/ReportStatus';
import { RapportAantallenTab } from '@/views/ReportStatistics/RapportAantallen/RapportAantallenTabs/RapportAantallenTabs';
import { WorkflowEnum } from '@/components/filters/work-flow-filter/WorkFlowFilter';

@Component<RapportAantallenPerType>({
  components: {
    WeekStatisticsView: () => import('@/components/week-statistics-view/WeekStatisticsView.vue'),
  },
})
export default class RapportAantallenPerType extends Vue {
  // #region @Prop

  @Prop({ default: 'regulier' })
  protected activeTab!: string;

  @Prop()
  protected filters!: Filters;

  @Prop()
  protected organization!: string;

  @Prop({ default: false })
  protected showSpecialTab!: boolean;

  @Prop({ default: () => [] })
  protected tabs!: RapportAantallenTab[];

  // #endregion

  // #region Prop

  protected currentFilters: Filters = {
    special: null,
  };

  protected isLoading = true;

  protected totals: ModifiedManagementReports[] = [];

  protected statisticsOptions: StatisticsOptions = {
    totalColumnName: 'reports_total',
  };

  protected tabStatus: {[key: string]: string[]} = {
    regulier: [
      ReportStatus.PLANNING_ORGANIZATION,
      ReportStatus.OPNAME_IN_PROGRESS,
      ReportStatus.OPNAME_REJECTED,
      ReportStatus.OPNAME_DONE,
      ReportStatus.IN_PROGRESS,
      ReportStatus.SUPERVISOR,
      ReportStatus.REJECTED,
      ReportStatus.SUBMITTED,
      ReportStatus.CHECKING,
      ReportStatus.VELDWERK_REJECTED,
      ReportStatus.STRUCTURAL_ENGINEER_REJECTED,
    ],
    ves: [
      ReportStatus.OPNAME_IN_PROGRESS,
      ReportStatus.OPNAME_REJECTED,
      ReportStatus.CHECKING,
    ],
    special: [
      ReportStatus.PLANNING_ORGANIZATION,
      ReportStatus.OPNAME_IN_PROGRESS,
      ReportStatus.OPNAME_REJECTED,
      ReportStatus.OPNAME_DONE,
      ReportStatus.IN_PROGRESS,
      ReportStatus.REJECTED,
      ReportStatus.SUBMITTED,
      ReportStatus.CHECKING,
      ReportStatus.VELDWERK_REJECTED,
      ReportStatus.STRUCTURAL_ENGINEER_REJECTED,
    ],
    nader_advies: [
      ReportStatus.ZIENSWIJZE_IN_PROGRESS,
      ReportStatus.ZIENSWIJZE_REJECTED,
      ReportStatus.ZIENSWIJZE_CHECKING,
      ReportStatus.ZIENSWIJZE_VELDWERK_REJECTED,
    ],
    ambtelijk_bezwaar: [
      ReportStatus.OBJECTION_PENDING_ASSIGNMENT,
      ReportStatus.OBJECTION_IN_PROGRESS,
      ReportStatus.OBJECTION_REJECTED,
      ReportStatus.OBJECTION_CHECKING,
      ReportStatus.OBJECTION_VELDWERK_REJECTED,
    ],
    bezwaar: [
      ReportStatus.OBJECTION_PENDING_ASSIGNMENT,
      ReportStatus.OBJECTION_IN_PROGRESS,
      ReportStatus.OBJECTION_REJECTED,
      ReportStatus.OBJECTION_CHECKING,
      ReportStatus.OBJECTION_VELDWERK_REJECTED,
    ],
    stuwmeer: [
      ReportStatus.SMR_IN_PROGRESS,
      ReportStatus.SMR_REJECTED,
      ReportStatus.SMR_CHECKING,
      ReportStatus.SMR_VELDWERK_REJECTED,
    ],
  }

  // #endregion

  // #region Lifecycle hooks / Initialze

  protected mounted(): void {
    this.initialize();
  }

  protected async initialize(): Promise<void> {
    this.currentFilters = { ...this.currentFilters, ...this.filters };
    this.isLoading = true;
    this.$emit('isLoading', this.isLoading);
    await this.getAmounts();
    this.isLoading = false;
    this.$emit('isLoading', this.isLoading);
  }

  // #endregion

  // #region Methods

  protected emitBreadcrumb(): void {
    this.$root.$emit('breadcrumbUpdated',
      {
        crumb: [
          { name: 'Rapport aantallen OHW' },
        ],
      });
  }

  protected parseTotals(response: any): void {
    this.totals = [];

    (response.data as ManagementReports[]).forEach((data: ManagementReports) => {
      if (data) {
        const item: ModifiedManagementReports = { ...data, ...{} };

        const isIMG = organizationUuid.img === this.organization;
        const statuses = '&statuses=["planning_organization", "opname_in_progress", "opname_rejected", "opname_done", "in_progress", "supervisor", "rejected", "submitted", "checking", "veldwerk_rejected"]';

        item.links = {
          total: `/reports?types=["${item.uuid}"]${statuses}&filter=total&${! isIMG ? `organizations=["${this.organization}"]` : ''}`,
          external: `/reports?types=["${item.uuid}"]${statuses}&filter=external&${! isIMG ? `organizations=["${this.organization}"]` : ''}`,
          planned: `/reports?types=["${item.uuid}"]${statuses}&filter=planned&${! isIMG ? `organizations=["${this.organization}"]` : ''}`,
          replanned: `/reports?types=["${item.uuid}"]${statuses}&filter=replanned&${! isIMG ? `organizations=["${this.organization}"]` : ''}`,
          todo: `/reports?types=["${item.uuid}"]${statuses}&filter=todo&${! isIMG ? `organizations=["${this.organization}"]` : ''}`,
        };

        if (this.activeTab === 'special') {
          item.links = {
            total: `/reports?types=["${item.uuid}"]${statuses}&filter=total_special&${! isIMG ? `organizations=["${this.organization}"]` : ''}`,
            external: `/reports?types=["${item.uuid}"]${statuses}&filter=external_special&${! isIMG ? `organizations=["${this.organization}"]` : ''}`,
            planned: `/reports?types=["${item.uuid}"]${statuses}&filter=planned_special&${! isIMG ? `organizations=["${this.organization}"]` : ''}`,
            replanned: `/reports?types=["${item.uuid}"]${statuses}&filter=replanned_special&${! isIMG ? `organizations=["${this.organization}"]` : ''}`,
            todo: `/reports?types=["${item.uuid}"]${statuses}&filter=todo_special&${! isIMG ? `organizations=["${this.organization}"]` : ''}`,
          };
        }

        this.totals.push(item);
      }
    });
  }

  // #endregion

  // #region API requests

  protected async getAmounts(): Promise<void> {
    if (! this.activeTab) {
      return;
    }

    const activeTab = this.tabs.find((tab: RapportAantallenTab) => tab.key === this.activeTab);
    if (activeTab) {
      this.currentFilters.workflow = activeTab.workflow;
    }

    if (this.showSpecialTab) {
      if (this.activeTab === 'special' || this.activeTab === 'total') {
        this.currentFilters.special = true;
      } else if (this.activeTab === 'regulier') {
        this.currentFilters.special = false;
      } else {
        this.currentFilters.special = null;
      }
    }

    this.currentFilters.status = this.activeTab === 'total'
      ? Object.keys(this.tabStatus).reduce((accumulator, key) => [...accumulator, ...this.tabStatus[key]], [] as string[])
      : this.tabStatus[this.activeTab];

    const response = await new Rpc()
      .dmz(this.organization)
      .rpcPost(this.payload, false)
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        this.isLoading = false;
      });
    if (! response) {
      return;
    }

    this.parseTotals(response);
  }

  // #endregion

  // #region Getters / Setters

  protected get columns(): StatisticsColumn[] {
    return [
      {
        key: 'report_type',
        name: 'Rapporttype',
        class: 'xs2 md2 lg2',
      },
      ...this.generalColumns,
    ];
  }

  protected get generalColumns(): StatisticsColumn[] {
    return [
      {
        key: 'external',
        name: 'Externe<br>Partij',
        class: 'xs2 text-right',
        format: () => 'Externe<br>Partij',
      },
      {
        key: 'planned',
        name: 'Toekomstig<br>gepland',
        class: 'xs2 text-right',
      },
      {
        key: 'replanned',
        name: 'Vervolg<br>Afspraak',
        class: 'xs2 text-right',
      },
      {
        key: 'todo',
        name: 'Onderhanden<br>werk',
        class: 'xs2 text-right',
      },
      {
        key: 'total',
        name: 'Totaal',
        class: 'xs2 text-right',
      },
    ];
  }

  protected get payload(): { signature: string; body: Filters; } {
    return {
      signature: 'management-reports:bureau-report-count:type',
      body: this.currentFilters,
    };
  }

  // #endregion

  // #region @Watchers

  @Watch('filters', { deep: true })
  protected filtersChanged(): void {
    this.initialize();
  }

  @Watch('organization')
  protected organizationChanged(): void {
    this.initialize();
  }

  @Watch('activeTab')
  protected activeTabChanged(): void {
    this.initialize();
  }

  // #endregion
}

// #region Interfaces

interface Filters {
  special?: boolean | null;
  status?: string[];
  departments?: string[];
  types?: string[];
  workflow?: WorkflowEnum | null;
}

interface ManagementReports {
  external: number;
  uuid: string;
  report_type: string;
  planned: number;
  replanned: number;
  status: string;
  todo: number;
  total: number;
}

interface ModifiedManagementReports extends ManagementReports {
  links?: {[key: string]: string};
}

// #endregion
