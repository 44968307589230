var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c("v-flex", { attrs: { xs12: "", md12: "" } }, [
            _c(
              "div",
              { staticClass: "elementPanel elementPanel--spaced" },
              [
                _c(
                  "v-card-text",
                  { staticClass: "pa-0" },
                  [
                    _vm.isLoading
                      ? _c("LoaderCard", {
                          attrs: { flat: "", type: "spinner--center" }
                        })
                      : _vm._e(),
                    !_vm.isLoading
                      ? _c("WeekStatisticsView", {
                          attrs: {
                            items: _vm.totals,
                            columns: _vm.columns,
                            options: _vm.statisticsOptions
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }